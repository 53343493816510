.navbar {
  width: 100%;
  height: 40px;
  position: fixed;
  top: 70px;
  left: 0px;
  z-index: 100;
  background-color: #d0d0d0;
  text-align: left;
  padding-left: 30px;
}

.dropdown {
  height: 40px;
  margin: 0px 20px;
  display: inline-block;
}

.dropdown p {
  font-weight: bold;
  line-height: 40px;
  display: inline-block;
}

.dropdown span {
  display: inline-block;
}
