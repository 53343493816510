.Heading h1 {
  margin-top: 32px;
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: 300;
}

.Form {
  text-align: left;
  padding: 20px;
  background-color: #eee;
  border-radius: 5px;
  margin-bottom: 40px;
}

.Form a {
  padding: 5px;
  margin: 0px 10px;
  text-decoration: none;
  border-radius: 5px;
}

.Form label {
  font-weight: 600;
  margin: 10px 0px 10px 0px;
}

.FileInput {
  height: 38px;
  width: 100%;
  margin: 0px;
}

.UploadButton {
  height: 34px;
  margin: 2px 10px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  border: none;
  border-radius: 3px;
}

.UploadButton:hover {
  box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.2);
}

.FileUploadIndicator div {
  margin-top: 15px;
}

.FileUploadIndicator span {
  padding: 18px 0px 0px 0px;
}

.ErrorMessage {
  color: red;
  margin-top: 10px;
}

.Button {
  margin-top: 20px;
  width: 100%;
  padding: 10px;
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  font-weight: 400;
  border: none;
  border-radius: 3px;
}

.Button:hover {
  box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.2);
}

@media only screen and (max-width: 576px) {
  .Button {
    width: 100%;
  }
}
