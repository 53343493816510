.HeadingCol {
  padding: 5px 0px 0px 0px;
}

.HeadingCol h1 {
  display: inline-block;
  margin: 5px 0px 5px 40px;
  font-size: 24px;
  font-weight: 500;
}

.HeadingCol button {
  display: inline;
  float: right;
  margin: 5px;
  padding: 0px;
  height: 35px;
  width: 35px;
  border: none;
  border-radius: 20px;
}

.HeadingCol button:hover {
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.2);
}

.UsersModal {
  padding: 10px 20px;
  max-height: 70%;
  width: 300px;
  overflow-y: hide;
  background-color: #EEE;
  color: black;
  position: fixed;
  top: 118px;
  right: 0px;
  z-index: 3000;
  border-radius: 5px 0px 0px 5px;
}

@media (max-width: 768px) {
    .UsersModal {
        top: 50px;
    }
}

.UsersModal h1 {
  font-size: 24px;
}

.UsersModal p {
  text-align: left;
  margin-left: 20px;
}

.RemoveButton {
  font-size: 13px;
  margin-right: 10px;
  cursor: pointer;
}

.Option button {
  margin: 10px 5px;
  width: 100%;
  border: none;
}

.Option button:hover {
  box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.1);
}

.Option p {
  display: inline-block;
  margin: 5px 0px 5px 30px;
  font-size: 18px;
  color: red;
}

.Option span {
  display: inline;
  float: right;
  margin: 6px 15px 5px 0px;
}

.Button {
  padding: 10px;
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  font-weight: 400;
  border: none;
  border-radius: 3px;
}

.Button:hover {
  box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.2);
}
