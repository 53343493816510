
.Heading h1 {
  margin: 200px 0px 40px 0px;
  font-size: 38px;
  font-weight: 500;
  margin-bottom: 40px;
}

.ErrorMessage {
  color: red;
  margin-top: 10px;
}

.QuestionHeading h1 {
  font-size: 38px;
  font-weight: 500;
  margin: 100px auto 10px;
}

.QuestionHeading h2 {
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 20px;
}

.Form {
  padding: 20px;
  background-color: #eee;
  border-radius: 5px;
  margin-bottom: 40px;
  text-align: left;
}

.Question {
  display: block;
  margin: 20px;
}

.Checkbox {
  margin-right: 15px;
  margin-left: 20px;
  height: 18px;
  width: 18px;
}

.Question label {
  font-size: 18px !important;
  font-weight: 400;
  color: black;
}

.QuestionButton {
  margin: 10px;
  width: 90%;
  padding: 10px;
  text-transform: uppercase;
  color: white;
  font-size: 18px;
  font-weight: 400;
  border: none;
  border-radius: 5px;
  line-height: 20px;
}

.QuestionButton:hover {
  box-shadow: 0px 0px 10px 6px rgba(0,0,0,0.1);
}

.Button {
  margin: 10px;
  width: 60%;
  padding: 10px;
  text-transform: uppercase;
  color: white;
  font-size: 18px;
  font-weight: 400;
  border: none;
  border-radius: 5px;
  line-height: 20px;
}

.Button:hover {
  box-shadow: 0px 0px 10px 6px rgba(0,0,0,0.1);
}


@media only screen and (max-width: 576px) {
  .Button {
    width: 90%;
  }
}
