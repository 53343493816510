.page {
  width: 100%;
  margin: 0px;
}

.content {
  position: relative;
  z-index: 10;
  margin-top: 70px;
}

@media (max-width: 768px) {
    .content {
      margin: 0px; /* Adjusted margin for mobile screens */
    }
}

.spinner {
  position: relative;
  z-index: 10;
  margin-top: 200px;
}

.subscription {
  position: relative;
  z-index: 10;
  margin-top: 70px;
  text-align: left;
}

.textbox {
  margin: 200px 0px 0px 200px;
}

.textbox h1 {
  margin: 0px 0px 20px;
}

.button {
  border: none;
  padding: 10px;
  border-radius: 3px;
}

.button:hover {
  box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.2);
}

.notificationModal {
  padding: 10px 0px;
  max-height: 500px;
  width: 350px;
  overflow-y: scroll;
  position: fixed;
  top: 72px;
  right: 0px;
  z-index: 3000;
  border-radius: 5px 0px 0px 5px;
}

.notificationModal::-webkit-scrollbar {
  display: none;
}

.notificationModal h1 {
  font-size: 24px;
  display: inline-block;
  margin: 0px 0px 0px 80px;
}

.ClearButton {
  display: inline;
  margin: 0px 20px;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}
