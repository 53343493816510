.Heading h1 {
  font-size: 38px;
  font-weight: 500;
  margin: 20px 0px 20px 0px;
}

.Heading h2 {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 20px;
}

.Form {
  padding: 20px;
  background-color: #eee;
  border-radius: 5px;
  margin-bottom: 40px;
  text-align: left;
}

.Label {
  display: block;
  margin: 15px;
}

.Label h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.Label p {
  font-size: 14px;
  margin-bottom: 10px;
}

.Label input {
  width: 100%;
}

.ErrorMessage {
  color: red;
  margin-top: 10px;
}

.Button {
  margin: 10px;
  width: 90%;
  padding: 15px;
  text-transform: uppercase;
  color: white;
  font-size: 18px;
  font-weight: 400;
  border: none;
  border-radius: 3px;
  line-height: 20px;
}

.Button:hover {
  box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.1);
}

.Button i {
  position: relative;
  top: 2px;
  padding-right: 10px;
  font-size: 18px;
  line-height: 20px;
}

@media only screen and (max-width: 576px) {
  .Button {
    width: 100%;
  }
}
