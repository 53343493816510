.HeadingCol {
  padding: 20px 0px 10px 40px;
  text-align: left;
}

.HeadingCol h1 {
  margin: 10px auto;
  font-size: 40px;
  font-weight: 300;
}

.HeadingCol p {
  margin: 0px 0px 0px 0px;
  font-size: 20px;
}

.BoldText {
  font-weight: 500;
}

.Assets a {
  padding: 5px;
  margin: 0px 10px;
  text-decoration: none;
  border-radius: 5px;
}

.Contract {
  padding: 5px;
  margin: 10px 0px 20px;
  text-decoration: none;
  border-radius: 5px;
}

.ServiceList span {
  margin: 0px 5px 0px;
  font-weight: 500;
}

.BrandingQuestionnaireList {
  margin: 10px;
}

.BrandingQuestionnaireList h2 {
  margin: 20px 0px 20px 0px;
}

.BrandingQuestionnaireList h6 {
  margin: 10px 0px 0px 0px;
}

.BrandingQuestionnaireList p {
  margin: 10px 0px 0px 10px;
}

.ViewButtonsCol {
  padding: 0px 0px 0px 40px;
  text-align: left;
}

.ViewButton {
  background-color: white;
  padding: 10px;
  margin: 10px 10px 0px 0px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
  border: solid black 1px;
  border-radius: 3px;
}

.ViewButton:hover {
  box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.2);
}

.ViewCol {
  margin: 20px 0px 20px 40px;
  padding: 20px 0px 30px 20px;
  text-align: left;
  background-color: #EFEFEF;
}

@media only screen and (max-width: 576px) {
  .ViewCol {
    margin: 20px 0px 20px 0px;
  }
}

.ViewCol h2 {
  margin: 0px 0px 10px 0px;
  font-size: 24px;
  font-weight: 600;
}

.ViewCol p {
  margin: 10px auto 0px;
}

.ViewCol label {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 500;
}

.ViewCol input {
  margin: 0px;
}

.FileUploadIndicator {
  padding: 5px;
}

.UploadButton {
  width: 100px;
  margin-top: 10px;
  padding: 10px;
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  font-weight: 400;
  border: none;
  border-radius: 3px;
}

.BackButtonCol {
  padding: 0px 0px 20px 40px;
  text-align: left;
}

.Button {
  width: 200px;
  padding: 10px;
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  font-weight: 400;
  border: none;
  border-radius: 3px;
}

.Button:hover {
  box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.2);
}
