

.Message {
  width: fit-content;
  min-width: 300px;
  max-width: 600px;
  padding: 10px;
  text-align: left;
  margin: 5px;
  border-radius: 5px;
}

.UserMessage {
  float: right;
  width: fit-content;
  min-width: 300px;
  max-width: 600px;
  padding: 10px;
  text-align: left;
  margin: 5px;
  border-radius: 5px;
}

/*
.UserMessage a {
  color: white;
}

.UserMessage span {
  color: white;
}
*/

.UserMessage img {
  background-color: white;
}

.MessageUsername {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
}

.MessageTime {
  margin: 0px 0px 0px 10px;
  font-size: 12px;
}

.MessageText {
  font-size: 16px;
  margin: 0px 0px;
}

.Image {
  margin: 10px 0px;
  max-width: 500px;
  max-height: 500px;
}

@media (max-width: 768px) {
    .Image {
      max-width: 300;
      max-height: 300px;
    }
}