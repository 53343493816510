.Main {
  padding-top: 30px;
}


.SideContent {
  text-align: left;
  padding: 10px 10px 0px 20px;
}

.SideContent h2 {
  font-size: 28px;
}

.SideContent h3 {
  font-size: 20px;
  margin: 5px 0px;
}


.SideContent p {
  margin: 0px;
}

.SideContent input {
  margin: 5px 0px 10px;
}

.SideContent textarea {
  width: 90%;
}

.Button {
  width: 80px;
  margin: 10px 10px 20px 0px;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
}

.Button:hover {
  box-shadow: 0px 0px 5px 2.5px rgba(0,0,0,0.1);
}
