.Content {
  background-color: white;
}

.SidebarCol {
  width: 250px;
  height: calc(100vh - 70px);
  background-color: #DDDDDD;
  overflow-y: scroll;
}

@media (max-width: 768px) {
    .SidebarCol {
        position: fixed; /* Position the overlay relative to the viewport */
        top: 0;
        left: 0;
        width: 100%; /* Make it cover the entire width */
        height: 100%; /* Make it cover the entire height */
        z-index: 1000; /* Ensure the overlay is on top of other elements */
    }
}

.SidebarMain {
  margin: 0px;
  background-color: #DDDDDD;
}

.SidebarMain::-webkit-scrollbar {
  display: none;
}

.ConversationRow {
  position: fixed;
  left: 250px;
  top: 70px;
  width: calc(100vw - 250px);
  margin: 0px;
  padding: 0px 10px 10px 10px;
}

@media (max-width: 768px) {
    .ConversationRow {
      top: 0px;
    }
}

.ConversationRowExpand {
  position: fixed;
  overflow: hidden;
  left: 0px;
  top: 70px;
  width: 100vw;
  margin: 0px;
}

@media (max-width: 768px) {
    .ConversationRowExpand {
      top: 0px;
    }
}

.ConversationCol {
  background-color: white;
}

.HeadingCol {
  background-color: white;
  height: 50px;
  margin: 0px;
  border-bottom: solid #EEE 2px;
}

.MessagesCol {
  height: calc(100vh - 130px);
  overflow-y: scroll;
  margin: 0px 0px 0px 0px;
  background-color: white;
}

.MessagesCol::-webkit-scrollbar {
  display: none;
}

.MessagesColExpand {
  position: fixed;
  top: 50px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow-y: scroll;
  margin: 0px 0px 0px 0px;
  background-color: white;
}

.MessagesColExpand::-webkit-scrollbar {
  display: none;
}

.InputCol {
  width: calc(100vw - 270px);
  position: fixed;
  bottom: 0px;
  margin: 0px auto;
}

.InputColExpand {
  width: 100vw;
  height: 60px;
  position: fixed;
  bottom: 0px;
  margin: 0px auto;
  overflow: hidden;
}

.FileUploadIndicator {
  margin: 15px auto 0px;
}

.Modal {
  padding: 10px 25px !important;
  position: fixed;
  top: 150px;
  text-align: center;
  border-radius: 3px;
}

.Modal h2 {
  font-size: 24px;
  font-weight: bold;
}

.Modal p {
  text-align: left;
  margin: 15px 0px 0px 0px;
}

.Button {
  width: 200px;
  padding: 10px;
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  font-weight: 400;
  border: none;
  border-radius: 3px;
}

.Button:hover {
  box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.2);
}
