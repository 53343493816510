.MessageInputForm {
  background-color: white;
  text-align: left;
}

.InputCol {
  width: calc(100% - 160px);
  display: inline-block;
  padding: 0px 10px 0px 0px;
  text-align: left;
}

@media (max-width: 768px) {
    .InputCol {
      width: calc(100% - 110px);
    }
}

.InputCol input {
  border: solid #EEE 1px;
  width: 100%;
  margin: 10px 0px 15px 5px;
  line-height: 1.5;
  font-size: 20px;
}

.InputCol textarea {
  border: solid #EEE 1px;
  width: 100%;
  height: 40px;
  top: 10px;
  position:relative;
  margin: 0px 0px 0px 0px;
  font-size: 20px;
  line-height: 1.5;

  padding: 5px;
  border-radius: 5px;
  outline: none;
  resize: none; /* Disable textarea resizing */
  overflow-x: hidden;
  overflow-y: hidden;
}

.EmojiCol {
  width: 50px;
  display: inline-block;
  padding: 0px;
  text-align: right;
}

.EmojiCol button {
  position: relative;
  top: 6px;
  padding: 5px 10px 0px 10px;
}

.Picker {
  float: right;
}

.AttachmentCol {
  width: 50px;
  display: inline-block;
  padding: 0px;
  text-align: right;
}

.AttachmentCol button {
  position: relative;
  top: 6px;
  padding: 5px 10px 0px 10px;
}

.SendCol {
  width: 58px;
  display: inline-block;
  padding: 0px;
  text-align: right;
  margin: 0px 0px 10px 0px; 
}

.SendCol button {
}

.Button {
  padding: 5px;
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  font-weight: 400;
  border: none;
  border-radius: 3px;
}

.Button:hover {
  box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.2);
}
