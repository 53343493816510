.SettingsContent {
  text-align: left;
  padding: 0px 40px;
  position: relative;
  top: 60px;
}

.SettingsContent h1 {
  font-size: 24px;
  font-weight: 600;
}

.SettingsContent h2 {
  font-size: 20px;
  font-weight: 500;
}

.SettingsContent p {
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
}

.ThemeModule {
  margin: 0px 0px 20px;
  padding: 0px 0px 10px;
  border-bottom: 1px solid #bbb;
}

.ThemeModule input {
  margin: 10px 0px;
}

.File {
  width: 100%;
  padding: 5px;
  margin: 10px 0px;
  background-color: #eee;
  border-radius: 3px;
}

.File a {
  text-decoration: none;
}

.File button {
  float: right;
  font-size: 16px;
  border: none;
}

.FileInput {
  height: 38px;
  width: 100%;
  margin: 0px;
}

.FileUploadIndicator div {
  margin-top: 15px;
}

.FileUploadIndicator span {
  padding: 18px 0px 0px 0px;
}

.ErrorMessage {
  color: red;
  margin-top: 10px;
}

.UploadButton {
  width: 100px;
  height: 34px;
  margin: 12px 10px 10px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  border: none;
  border-radius: 3px;
}

.UploadButton:hover {
  box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.2);
}

.ButtonCol button {
  margin: 0px 0px 0px 10px;
  padding: 5px;
  width: 150px;
  border: none;
  border-radius: 3px;
}

.ButtonCol button:hover {
  box-shadow: 0px 0px 5px 2.5px rgba(0,0,0,0.1);
}

.spinner {
  position: relative;
  z-index: 10;
  padding-top: 200px;
}
