.Page {
  padding: 35px 0px;
}


.Details {
  text-align: left;
}

.Details hr {
  height: 2px;
  opacity: 1;
}

.Details h2 {
  font-size: 18px;
  font-weight: 400;
}

.PermissionsBlock {
  padding: 0px 40px 0px 12px;
  margin: 20px 0px 0px;
  text-align: left;
}

.PermissionsBlock h2 {
  font-size: 20px;
  font-weight: 500;
}

.PermissionsBlock hr {
  height: 2px;
  opacity: 1;
}

.Assets {
  margin: 20px 0px 0px;
  text-align: left;
}

.Assets h2 {
  font-size: 20px;
  font-weight: 500;
}

.Assets hr {
  height: 2px;
  opacity: 1;
}

.TableCol {
  border-top: 2px solid black;
  padding: 0px;
  width: 100%;
}

.TableCol input {
  margin: 10px 0px 10px 0px;
  float: left;
  width: 250px;
  border-radius: 3px;
  border: 1px solid grey;
}

.TableCol table {
  width: 100%;
  margin: 10px auto;
}

.TableCol td, .TableCol th {
  padding: 8px;
  text-align: left;
}

.TableCol tr:hover {background-color: #ddd;}

.TableCol th {
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 16px;
  font-weight: bold;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  user-select: none; /* supported by Chrome and Opera */
 -webkit-user-select: none; /* Safari */
 -khtml-user-select: none; /* Konqueror HTML */
 -moz-user-select: none; /* Firefox */
 -ms-user-select: none; /* Internet Explorer/Edge */
}

.TableCol td {
  border-bottom: 1px solid grey;
}

.Button {
  float: left;
  width: 100px;
  margin: 20px 20px 0px 0px;
  padding: 3px 10px;
  text-transform: uppercase;
  color: white;
  font-size: 15px;
  font-weight: 400;
  border: none;
  border-radius: 3px;
}

.Button:hover {
  box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.2);
}
