.content {
  margin: 0px;
  position: relative;
  top: 60px;
}

.text {
  margin: 0px 0px 20px 0px;
  padding: 0px 40px;
}

.text h1 {
  font-weight: bold;
  font-size: 45px;
  margin: 0px 0px 20px 0px;
}

.text p {
  font-weight: 500;
  font-size: 20px;
}

.package {
  border-radius: 15px;
  height: 450px;
  border: 2px solid #ddd;
  margin: 0px;
}

.title {
  width: 90%;
  margin: 20px auto;
  padding: 5px;
  background-color: #f2cf35;
  border-radius: 5px;
  text-align: center;
}

.title h2 {
  margin: 0px;
  color: white;
  font-weight: bold;
}

.subtext {
  width: 90%;
  margin: 10px auto;
}

.subtext h2 {
  margin-left: 10px;
  font-size: 16px;
  text-align: left;
}

.subtext h3 {
  margin-left: 10px;
  font-size: 12px;
  text-align: left;
}

.subtext button {
  width: 150px;
  margin: 20px 10px;
  padding: 5px;
  background-color: #f2cf35;
  border-radius: 5px;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.subtext button:hover {
  box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.2);
}

.subtext p {
  text-align: left;
}

.subtext span {
  margin-right: 10px;
}
