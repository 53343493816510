.Notification {
  padding: 0px;
  margin: 0px;
}

.NotificationCol {
  margin: 5px 10px;
  border-radius: 3px;
}

.NotificationCol:hover {
  background-color: white;
}

.NotificationMain {
  padding: 10px 0px;
}

.NotificationIndicator {
}

.Number {
  margin: 5px 0px;
  height: 23px;
  width: 23px;
  border-radius: 20px;
  font-size: 12px;
  padding: 2px;
}

.NotificationMessage {
  text-align: left;
}

.NotificationMessage p {
  font-size: 13px;
  margin: 0px;
}


.NotificationBottom {
  padding-bottom: 10px;
}

.Date p {
  font-size: 10px;
  margin: 0px;
  text-align: right;
}

.Delete p {
  cursor: pointer;
  font-size: 12px;
  text-decoration: underline;
  margin: 0px;
  color: #bb0000;
}
