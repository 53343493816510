.Modal {
  top: 150px;
  z-index: 3000;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
}

.Modal h2 {
  display: inline-block;
  margin: 0px 0px 20px 40px;
}

.Modal input {
  margin: 10px 0px;
}

.CloseButton {
  width: 40px;
  height: 40px;
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  font-weight: 400;
  border: none;
  border-radius: 3px;
}

.CloseButton:hover {
  box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.2);
}

.OptionsButton {
  width: 90%;
  background-color: white;
  margin: 5px auto;
  padding: 10px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
  border-radius: 3px;
}

.OptionsButton:hover {
  box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.2);
}

.Button {
  padding: 10px;
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  font-weight: 400;
  border: none;
  border-radius: 3px;
}

.Button:hover {
  box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.2);
}
