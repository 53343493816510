.SettingsContent {
  text-align: left;
  padding: 0px 40px;
  position: relative;
  top: 100px;
}

.SettingsContent h1 {
  font-size: 24px;
  font-weight: 600;
}

.SettingsContent h2 {
  font-size: 20px;
  font-weight: 500;
}

.SecurityModule {
  margin: 0px 0px 20px;
  padding: 0px 0px 10px;
  border-bottom: 1px solid #bbb;
}

.SecurityModule button {
  margin: 20px 0px;
  padding: 5px;
  width: 150px;
  border: none;
  border-radius: 3px;
}

.SecurityModule button:hover {
  box-shadow: 0px 0px 5px 2.5px rgba(0,0,0,0.1);
}

.SecurityModule select {
  margin: 10px 0px;
}

.WarningText {
  font-size: 12px;
}

.Modal {
  padding: 10px 25px !important;
  position: fixed;
  top: 200px;
  text-align: center;
  border-radius: 3px;
}

.Modal h2 {
  font-size: 24px;
  font-weight: bold;
}

.Modal p {
  margin: 15px 0px 0px 0px;
}

.Modal button {
  margin: 20px 0px 0px;
  padding: 5px;
  width: 150px;
  border: none;
  border-radius: 3px;
}
