.Circle {
  position: relative;
  display: inline;
  height: 20px;
  width: 20px;
  margin: 10px;
  border-radius: 10px;
}

.Circle span {
  position: relative;
  font-size: 18px;
  cursor: pointer;
  color: black;
}

.Popup {
  visibility: hidden;
  width: 200px;
  position: absolute;
  top: 0px;
  left: 20px;
  background: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
}

.Popup p {
}
