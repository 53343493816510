.content {
  margin: 0px auto;
  position: relative;
  top: 40px;
  width: 80%;
}

.content h1 {
  text-align: left;
  font-weight: bold;
  font-size: 32px;
}

.half_module {
  height: 200px;
  margin: 10px 0px;
  text-align: left;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 15px;
}

.half_module button {
  font-weight: bold;
  margin: 0px 15px 0px 0px;
  border-radius: 5px;
  border: none;
  background-color: #f2cf35;
  color: white;
  padding: 5px 10px;
}

.half_module button:hover {
  box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.3);
}

.vacation_table {
  margin: 15px 0px;
  width: 70%;
}

.vacation_table th {
  padding-left: 10px;
}

.vacation_table td {
  padding-left: 35px;
}

.pointer {
  cursor: pointer;
}
