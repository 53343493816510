.Sidebar {
  position: fixed;
  width: 200px;
  height: calc(100vh - 70px);
}

.Links {
  position: absolute;
  top: 70px;
  padding: 0px 20px 0px 40px;
  text-align: left;
}

.Link {
  text-decoration: none;
  cursor: pointer;
}

.Link:hover {
  text-decoration: underline;
  color: black;
}

.Main {
  position: relative;
  left: 200px;
  width: calc(100vw - 200px);
  margin: 0px;
  padding: 0px 10px 10px 10px;
}
