.content {
  margin: 0px;
  position: relative;
  top: 40px;
}

.form {
  padding: 40px 20px 0px 40px;
  text-align: left;
  min-width: 650px;
}

.form form {
  margin: 20px 0px;
}

.form h1 {
  font-weight: bold;
  font-size: 40px;
}

.textInput {
  margin: 15px 0px;
}

.form span {
  font-weight: 500;
}

.form p {
  font-size: 16px;
}

.form button {
  margin: 20px 0px 0px 0px;
  font-weight: bold;
  font-size: 24px;
  border-radius: 5px;
  border: none;
  background-color: #f2cf35;
  color: white;
  padding: 10px 40px;
}

.form button:hover {
  box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.3);
}

.media {
  margin: 0px;
  padding: 40px 40px;
}

.picture {
  margin: 0px;
  width: 100%;
  height: 70vh;
  border: 2px solid grey;
}
