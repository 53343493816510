.Heading h1 {
  font-size: 32px;
  font-weight: 300;
  margin: 20px 0px;
}

.Form {
  padding: 20px 20px 20px 40px;
  background-color: #eee;
  border-radius: 5px;
  margin-bottom: 40px;
  text-align: left;
}

.Form h5 {
  margin: 0px 0px 10px 0px;
  font-size: 20px;
}

.Form p {
  margin: 0px 0px 10px 0px;
  font-size: 16px;
}

.Button {
  margin-top: 20px;
  width: 100%;
  padding: 10px;
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  font-weight: 400;
  border: none;
  border-radius: 3px;
}

@media only screen and (max-width: 576px) {
  .Button {
    width: 100%;
  }
}


.ReviewDiv {
  text-align: left;
  width: 100%;
  margin: 120px auto 20px;
  padding: 20px 30px 20px;
  border-radius: 5px;
}

.ServiceList span {
  font-weight: 600;
  margin: 0px 5px 0px;
}

.AccountList {
  margin-top: 10px;
}

.Accounts {
  background-color: white;
  width: 80%;
  margin: 10px 0px;
  padding: 5px 0px 5px 10px;
  border: 1px solid black;
}

.Accounts p {
  margin: 5px 0px;
}

.BrandingQuestionnaireList {
  background-color: white;
  width: 95%;
  margin: 10px 0px;
  padding: 5px 20px 5px 20px;
  border: 1px solid black;
}

.BrandingQuestionnaireList h6 {
  margin-top: 8px;
  font-size: 14px;
}

.BrandingQuestionnaireList p {
  font-size: 13px;
  margin: 5px 0px 5px 5px;
}

.Assets {
  margin: 20px 0px;
}

.Assets a {
  padding: 5px;
  margin: 0px 10px;
  text-decoration: none;
  border-radius: 5px;
}

.Modal {
  top: 300px;
  z-index: 3000;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
}

.Focus {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 150;
}
