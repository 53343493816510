.header {
  position: fixed;
  top: 0px;
  height: 70px;
  margin: 0px;
  background-color: white;
  z-index: 20;
}

.WelcomeText {
  font-size: 15px;
  font-weight: 300;
}

.logoCol {
  text-align: left;
}

.logoCol img {
  cursor: pointer;
}

.logoCol p {
  display: inline-block;
  line-height: 70px;
  margin: 0px 0px 0px 10px;
}

.logo {
  height: 65px;
  margin-top: 0px;
}

.linksCol {}

.linksCol p {
  font-weight: bold;
  line-height: 70px;
  float: right;
  margin-right: 20px;
  color: black;
}

.linksCol button {
  font-weight: bold;
  float: right;
  margin: 18px 20px 0px 0px;
  border-radius: 5px;
  border: none;
  background-color: #f2cf35;
  color: white;
  padding: 5px;
}

.linksCol button:hover {
  box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.3);
}

.buttonCol p {
  font-weight: bold;
  line-height: 70px;
  float: right;
  margin-right: 20px;
  color: black;
}

.buttonCol button {
  float: right;
  margin: 16px 20px 0px 0px;
  padding: 5px 10px 10px 10px;
  height: 40px;
  border-radius: 5px;
  border: none;
}

.notificationButton span {
  font-size: 20px;
}

.notificationBadge {
  width: 18px;
  font-size: 12px;
  font-weight: bold;
  background-color: red;
  position: relative;
  border-radius: 10px;
  color: white;
  top: -33px;
  left: 18px;
}
