.Modal {
  padding: 10px 25px !important;
  position: fixed;
  top: 150px;
  text-align: center;
  border-radius: 3px;
}

.Modal h2 {
  font-size: 24px;
  font-weight: bold;
}

.Modal p {
  text-align: left;
  margin: 15px 0px 0px 0px;
}

.FileUploadIndicator {
  margin: 15px auto 0px;
}

.Button {
  width: 200px;
  padding: 10px;
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  font-weight: 400;
  border: none;
  border-radius: 3px;
}

.Button:hover {
  box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.2);
}
