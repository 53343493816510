.HeadingCol {
  padding: 20px 0px 10px 40px;
  text-align: left;
}

.HeadingCol h1 {
  margin: 10px auto;
  font-size: 40px;
  font-weight: 300;
}

.HeadingCol p {
  margin: 0px 0px 0px 0px;
  font-size: 20px;
}

.BoldText {
  font-weight: 500;
}

.ServiceList span {
  margin: 0px 5px 0px;
  font-weight: 500;
}

.ViewButtonsCol {
  padding: 0px 0px 0px 40px;
  text-align: left;
}

.ViewButton {
  background-color: white;
  padding: 10px;
  margin: 10px 10px 0px 0px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
  border: solid black 1px;
  border-radius: 3px;
}

.ViewButton:hover {
  box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.1);
}

.ViewCol {
  margin: 20px 0px 20px 40px;
  padding: 20px 0px 30px 20px;
  text-align: left;
  background-color: #EFEFEF;
}

@media only screen and (max-width: 576px) {
  .ViewCol {
    margin: 20px 0px 20px 0px;
  }
}

.ViewCol h2 {
  margin: 0px 0px 10px 0px;
  font-size: 24px;
  font-weight: 600;
}

.ViewCol p {
  margin: 10px auto 0px;
}

.ViewCol label {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 500;
}

.ViewCol input {
  margin: 0px;
}

.File {
  width: 60%;
  padding: 5px;
  margin: 10px 0px;
  border-radius: 3px;
}

.File a {
  text-decoration: none;
}

.textPermission {
  padding: 0px 10px;
  width: 50px;
  border-radius: 3px;
}

.permission {
  padding: 0px 10px;
  height: 25px;
  width: 25px;
  border-radius: 3px;
}

.requeststatus {
  padding: 0px 10px;
  width: 90px;
  border-radius: 3px;
}

.permissionsCol {
  padding: 0px;
  margin: 10px 0px;
}

.permissionsCol h3 {
  font-size: 20px;
}

.generalPermissionsLine {
  margin: 10px 0px;
}

.TableCol {
  border-top: 2px solid black;
  padding: 0px;
}

.TableCol input {
  margin: 10px 0px 10px 0px;
  float: left;
  width: 250px;
  border-radius: 3px;
  border: 1px solid grey;
}

.TableCol table {
  width: 100%;
  margin: 10px auto;
}

.TableCol td, .TableCol th {
  padding: 8px;
  text-align: left;
}

.TableCol tr:hover {background-color: #ddd;}

.TableCol th {
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 16px;
  font-weight: bold;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  user-select: none; /* supported by Chrome and Opera */
 -webkit-user-select: none; /* Safari */
 -khtml-user-select: none; /* Konqueror HTML */
 -moz-user-select: none; /* Firefox */
 -ms-user-select: none; /* Internet Explorer/Edge */
}

.TableCol td {
  border-bottom: 1px solid grey;
}

.EditButton {
  display: inline-block;
  margin: 0px 0px 0px 20px;
  border: none;
  border-radius: 3px;
  padding: 0px 10px;
}

.ConfigureButton {
  margin: 20px 0px;
  border: none;
  border-radius: 3px;
  padding: 0px 10px;
}

.BackButtonCol {
  padding: 0px 0px 20px 40px;
  text-align: left;
}

.Button {
  width: 200px;
  padding: 10px;
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  font-weight: 400;
  border: none;
  border-radius: 3px;
}

.Button:hover {
  box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.2);
}
