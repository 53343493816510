
.AccountDiv {
  text-align: left;
  height: 50px;
  width: 100%;
  margin: 10px 0px;
  background-color: grey;
  color: white;
  display: inline-block;
  border-radius: 5px;
}

.AccountDiv h5 {
  display: inline-block;
  margin-left: 20px;
  font-weight: 500;
}

.Show {
  display: inline-block;
  background-color: white;
  color: black;
  width: 100%;
  margin: 10px auto 0px;
}

.Heading h1 {
  font-size: 38px;
  font-weight: 300;
  margin-top: 40px;
  margin-bottom: 10px;
}

.Security {
  color: #555;
  font-size: 14px;
  line-height: 1.5;
}

.Security span {
  font-size: 14px;
  margin: 0px 10px 3px 0px;
  vertical-align: middle;
  line-height: 1.5;
}

.Form {
  padding: 20px;
  background-color: #eee;
  border-radius: 5px;
  margin-bottom: 40px;
  text-align: left;
}

.FormInput {
  margin: 0px 0px 10px 0px;
}

.FormInput h2 {
  text-align: center;
  font-size: 24px;
}

.FormInput h3 {
  text-align: center;
  font-size: 18px;
}

.FormInput p {
  font-size: 16px;
  margin: 5px;
}

.FormInput span {
  font-size: 18px;
}

.Checkbox {
  text-align: left;
  height: 18px;
  width: 18px;
  margin-right: 15px;
}

.TextForm {
  height: 30px;
  width: 100%;
  margin: 5px 0px 5px 0px;
}


.ErrorMessage {
  color: red;
  margin-top: 10px;
}

.Button {
  margin: 20px 10px 10px;
  width: 90%;
  padding: 15px;
  text-transform: uppercase;
  color: white;
  font-size: 18px;
  font-weight: 400;
  border: none;
  border-radius: 3px;
  line-height: 20px;
}

.Button:hover {
  box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.1);
}

.Button i {
  position: relative;
  top: 2px;
  padding-right: 10px;
  font-size: 18px;
  line-height: 20px;
}

@media only screen and (max-width: 576px) {
  .Button {
    width: 100%;
  }
}
