.Heading h1 {
  font-size: 32px;
  font-weight: 300;
  margin-top: 40px;
  margin-bottom: 20px;
}

.Form {
  padding: 20px;
  background-color: #eee;
  border-radius: 5px;
  margin-bottom: 40px;
  text-align: left;
}

.MonthLabel {
  background-color: #777;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
}

.MonthLabelSwitch input {
  height: 20px;
  width: 45px;
  margin-right: 20px;
}

.MonthLabelSwitch label {
  font-size: 20px;
  font-weight: 500;
  color: white;
}

.EventsDiv {
  margin-bottom: 20px;
}

.Checkbox {
  text-align: left;
  height: 18px;
  width: 18px;
  margin-right: 15px;
  margin-left: 0px !important;
}

.TextForm {
  height: 30px;
  width: 100%;
  margin: 5px 0px 5px 0px;
}

.CustomDateContainer {
  margin: 10px;
}

.CustomDate {
  display: inline-block;
  margin-right: 20px;
}

.CustomDateName {
  display: inline-block;
  width: 300px;
  margin-right: 20px;
}

.DeleteCustomDate {
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 400;
  border: none;
  border-radius: 3px;
  line-height: 20px;
}

.DeleteCustomDate:hover {
  box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.1);
}

.ErrorMessage {
  color: red;
  margin-top: 10px;
}

.Button {
  margin: 10px;
  width: 90%;
  padding: 15px;
  text-transform: uppercase;
  color: white;
  font-size: 18px;
  font-weight: 400;
  border: none;
  border-radius: 3px;
  line-height: 20px;
}

.Button:hover {
  box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.1);
}

.Button i {
  position: relative;
  top: 2px;
  padding-right: 10px;
  font-size: 18px;
  line-height: 20px;
}

@media only screen and (max-width: 576px) {
  .Button {
    width: 100%;
  }
}















//
