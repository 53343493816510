.Heading h1 {
  margin: 80px 0px 20px 0px;
  font-size: 38px;
  font-weight: 300;
}

.Heading p {
  font-size: 16px;
  font-weight: 300;
}

.Form {
  padding: 20px;
  background-color: #eee;
  border-radius: 5px;
  margin-bottom: 40px;
}

.FormInput {
  margin: 0px 0px 0px;
  text-align: left;
}

.FormInput h2 {
  margin: 0px 0px 5px 0px;
  font-size: 20px;
}

.FormInput input {
  height: 30px;
  width: 100%;
}

.ErrorMessage {
  color: red;
  margin-top: 10px;
}

.AddButton {
  margin-top: 10px;
  color: white;
  border: none;
  border-radius: 3px;
}

.RemoveButton {
  margin-bottom: 5px;
  color: white;
  border: none;
  border-radius: 3px;
}

.AddButton i {
  margin-top: 5px;
}

.Button {
  margin-top: 20px;
  width: 100%;
  padding: 10px;
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  font-weight: 400;
  border: none;
  border-radius: 3px;
}

@media only screen and (max-width: 576px) {
  .Button {
    width: 100%;
  }
}
