.SidebarRow {
  margin: 0px;
}

.SidebarOption {
  height: 40px;
  padding: 8px 10px;
}

.SidebarOption:hover {
  background-color: #CCCCCC;
  cursor: pointer;
}

.SidebarOption p {
  font-size: 14px;
}

.ControlsCol {
  padding: 10px 0px !important;
  text-align: left;
}

.Arrow {
  margin: 0px 10px 0px 0px;
  font-size: 14px;
}

.GroupMessageCol {
  padding: 10px 0px !important;
  text-align: left;
}

.GroupMessageCol h2 {
  cursor: pointer;
  margin: 10px 0px;
  font-size: 14px;
  font-weight: bold;
}

.DirectMessageCol {
  padding: 10px 0px !important;
  text-align: left;
}

.DirectMessageCol h2 {
  cursor: pointer;
  margin: 10px 0px;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
}

.Count {
  text-align: center;
  border-radius: 3px;
  width: 20px;
}

.Count p {
  margin: 0px;
  font-weight: bold;
}

.OpenFilterButton {
  margin-left: 50px;
  width: 25px;
  height: 25px;
  border: none;
  background-color: #eee;
}

.OpenFilterButton span {
  font-size: 14px;
}

.FilterButton {
  border: none;
  border-radius: 3px;
  font-size: 13px;
  margin: 5px 5px 5px 0px;
  background-color: #DDD;
}

.Selected {
  background-color: #888;
  color: white;
}

.Button {
  padding: 10px;
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  font-weight: 400;
  border: none;
  border-radius: 3px;
}

.Button:hover {
  box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.2);
}
