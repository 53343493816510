.content {
  margin: 0px;
  position: relative;
  top: 100px;
}

.text {
  padding: 40px 20px 0px 40px;
  text-align: center;
  min-width: 650px;
}

.text h1 {
  font-weight: bold;
  font-size: 48px;
}

.text h1 span {
  font-size: 40px;
}

.text p {
  font-weight: 400;
  font-size: 20px;
}

.text button {
  margin: 10px 0px 0px 0px;
  font-weight: bold;
  font-size: 18px;
  border-radius: 5px;
  border: none;
  background-color: #f2cf35;
  color: white;
  padding: 5px 30px;
}

.text button:hover {
  box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.3);
}
