.Main {
  position: relative;
  top: 50px;
}

.Module {
  background-color: #eee;
}

.Module hr {
  margin: 0px;
}

.Section {
  margin: 15px 0px 0px;
  padding: 0px 20px 15px;
  text-align: left;
}

.Section h2 {
  font-size: 18px;
}

.File {
  width: 100%;
  padding: 5px;
  margin: 10px 0px;
  border-radius: 3px;
}

.File a {
  text-decoration: none;
}

.File button {
  float: right;
  font-size: 10px;
  border: none;
  padding: 0px;
  margin: 0px 10px 0px 0px;
}

.FileInput {
  height: 38px;
  width: 100%;
  margin: 10px 0px;
}

.UploadButton {
  height: 34px;
  margin: 12px 10px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  border: none;
  border-radius: 3px;
}

.UploadButton:hover {
  box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.2);
}

.FileUploadIndicator div {
  margin-top: 15px;
}

.FileUploadIndicator span {
  padding: 18px 0px 0px 0px;
}

.ErrorMessage {
  color: red;
  margin-top: 10px;
}

.Button {
  margin-top: 20px;
  width: 100%;
  padding: 10px;
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  font-weight: 400;
  border: none;
  border-radius: 3px;
}

.Button:hover {
  box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.2);
}

@media only screen and (max-width: 576px) {
  .Button {
    width: 100%;
  }
}
