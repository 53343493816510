.SettingsContent {
  text-align: left;
  padding: 0px 40px;
  position: relative;
  top: 60px;
}

.SettingsContent h1 {
  font-size: 24px;
  font-weight: 600;
}

.SettingsContent h2 {
  font-size: 20px;
  font-weight: 500;
}

.SettingsContent p {
  font-size: 16px;
  font-weight: 400;
  margin: 10px 10px;

}

.BillingModule {
  margin: 0px 0px 20px;
  padding: 0px 0px 10px;
  border-bottom: 1px solid #bbb;
}

.BillingModule button {
  margin: 20px 0px;
  padding: 5px;
  width: 150px;
  border: none;
  border-radius: 3px;
}

.BillingModule button:hover {
  box-shadow: 0px 0px 5px 2.5px rgba(0,0,0,0.1);
}

.spinner {
  position: relative;
  z-index: 10;
  padding-top: 200px;
}
