.sidebar {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 50px;
  background-color: #ddd;
  z-index: 60;
}

.navbuttons {
  position: relative;
  top: 100px;
}

.navbuttons button {
  margin: 10px 0px;
  border: none;
  border-radius: 3px;
  width: 35px;
  height: 35px;
}

.navbuttons button:hover {
  box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.3);
}

.bottombuttons {
  position: absolute;
  bottom: 10px;
}

.bottombuttons button {
  margin: 10px 0px;
  border: none;
  border-radius: 3px;
  width: 35px;
  height: 35px;
}

.bottombuttons button:hover {
  box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.3);
}

.honeycomb {
  fill: #f2cf35;
}

.sidebarExtension {
  position: fixed;
  top: 0px;
  left: -150px;
  height: 100vh;
  width: 150px;
  background-color: #ddd;
  z-index: 50;
  transition: 0.5s;
  box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.3);
}

.navbuttonDescriptions {
  position: relative;
  top: 100px;
  text-align: left;
}

.navbuttonDescriptions p {
  line-height: 35px;
  margin: 10px 0px 20px 10px;
  font-weight: bold;
  cursor: pointer;
}


.bottombuttonDescriptions {
  position: absolute;
  bottom: 10px;
  text-align: left;
}

.bottombuttonDescriptions p {
  line-height: 35px;
  margin: 20px 0px 10px 10px;
  font-weight: bold;
  cursor: pointer;
}
